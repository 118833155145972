import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import {
  TxtDefaultBrownResp,
  //TxtDefaultMediumDarkResp,
  TxtLargeBoldLightResp,
  TxtMediumBoldDarkResp,
  TxtLargeMediumDarkResp,
  TxtSmallMediumAccentResp,
  TxtDefaultMediumAccentResp,
} from 'elements/new-design/Typography';
import { TxtLinkDefaultBrown } from 'elements/links/Links';
import { Config } from 'utils/Config';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  FlexBetween,
  FlexBetweenFullWidth,
  FlexCenterCenter,
  FlexCenterCenterFullWidth,
  FlexColumn,
  //FlexColumnSpaceBetween,
} from 'elements/containers/Containers';
import { WarningIconWarning } from 'elements/new-design/icons/SymbolIcons';
import { MarginLeft } from 'elements/distance/Margins';
import { getTrainLineFromProducLineId } from 'utils/Helpers';
import { Constants } from 'utils/Constants';

type CriticalDeviationProps = {
  deviation: any;
  handleGetStationName: (stationeNameShorthand: string) => string;
};

type StyleProps = {
  isCritical: boolean;
};

type TrainLineProps = {
  trainLine: string;
};

const DeviationContainer = styled.div`
  ${(_: StyleProps) => ''}
  margin-bottom: 2.4rem;
  box-shadow: ${(props) => props.theme.newcolors.borderBoxshadow};
  border-radius: 2rem;
`;

const CriticalDeviationHeader = styled(FlexBetween)`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.newcolors.bgLightTeal};
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const CriticalDeviationHeaderSubSection = styled(FlexCenterCenterFullWidth)`
  background-color: ${(props) => props.theme.newcolors.bgLightTeal};
`;

const TrainLine = styled.div`
  ${(_: TrainLineProps) => ''}
  border-top-left-radius: 2rem;
  background-color: ${(props) =>
    props.trainLine === 'F5'
      ? props.theme.newcolors.bgBlue
      : props.trainLine === 'R50'
      ? props.theme.newcolors.bgRed
      : props.theme.newcolors.bgGreen};
  padding: 0.5rem 1.5rem;
`;

const AffectedJourney = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.newcolors.bgLightTeal};
  padding: 0.5rem 1.5rem;
`;

// padding: 1rem 1.5rem;
const DeviationText = styled(TxtSmallMediumAccentResp)`
  align-self: flex-start;
  font-weight: normal;
  padding: 1.5rem;
`;

const ReadMore = styled(TxtLinkDefaultBrown)`
  align-self: end;
  padding: 0.5rem 1.5rem;
`;

const CriticalHeader = styled(CriticalDeviationHeader)`
  background-color: ${(props) => props.theme.newcolors.bgLightTeal};
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  justify-content: flex-start;
`;

const CriticalDescription = styled(TxtSmallMediumAccentResp)`
  align-self: flex-start;
  font-weight: normal;
  padding: 1.5rem;
`;

const FlexCenterCenterFullWidthOffset = styled(FlexCenterCenterFullWidth)`
  position: relative;
  left: -5rem;
`;

const formatDescription = (description: string) => description.split('\n');

export const CriticalDeviation = ({ deviation, handleGetStationName }: CriticalDeviationProps): JSX.Element => {
  const { currentLanguage, translate } = useI18n();
  const affectedTrain = deviation.affectedTrainServices[0];
  const opsTrafficUrl = Config().opsTrainCardUrl;
  const { device } = useBreakpoints();

  const resolveStationName = (idFromAffectedService, fallbackFullName) => {
    const primary = handleGetStationName(idFromAffectedService);
    if (primary != idFromAffectedService) return primary;
    return fallbackFullName;
  };

  const headerFromTo = () => {
    // Get the line servive data
    return `${resolveStationName(
      affectedTrain.origin,
      '...',
    )} (${affectedTrain.originDepartureTime.formatTime()}) - ${resolveStationName(
      affectedTrain.destination,
      '...',
    )} (${affectedTrain.destinationArrivalTime.formatTime()})`;
  };

  const mainFromTo = () => {
    // Get the "segment" to from data, i.e. the data on deviation itself.
    return `${resolveStationName(deviation.stationFrom, deviation.stationFrom)} - ${resolveStationName(
      deviation.stationTo,
      deviation.stationTo,
    )}`;
  };

  /*
  <FlexBetweenFullWidth>
              <TxtDefaultBoldBrownResp>
                {translate('FROM')} - {translate('TO')}
              </TxtDefaultBoldBrownResp>
            </FlexBetweenFullWidth>
*/

  return (
    <DeviationContainer isCritical={deviation.messageAlert}>
      {affectedTrain ? (
        // i.e. case for disruption affecting a single train service.
        // {` (${affectedTrain.originDepartureTime.diffTo(affectedTrain.destinationArrivalTime)})`}
        <FlexColumn>
          <CriticalDeviationHeader>
            <FlexCenterCenter>
              <TrainLine trainLine={getTrainLineFromProducLineId(deviation.affectedProductLineIds[0])}>
                <TxtLargeBoldLightResp>
                  {getTrainLineFromProducLineId(deviation.affectedProductLineIds[0])}
                </TxtLargeBoldLightResp>
              </TrainLine>
              <MarginLeft margin={1}>
                <TxtMediumBoldDarkResp>{affectedTrain?.trainNumber}</TxtMediumBoldDarkResp>
              </MarginLeft>
            </FlexCenterCenter>
            {device !== Constants.DEVICE_XSMALL && (
              <FlexCenterCenterFullWidthOffset>
                <TxtDefaultMediumAccentResp>{headerFromTo()}</TxtDefaultMediumAccentResp>
              </FlexCenterCenterFullWidthOffset>
            )}
          </CriticalDeviationHeader>

          {device === Constants.DEVICE_XSMALL && (
            <CriticalDeviationHeaderSubSection>
              <TxtDefaultMediumAccentResp>{headerFromTo()}</TxtDefaultMediumAccentResp>
            </CriticalDeviationHeaderSubSection>
          )}

          <AffectedJourney>
            <FlexBetweenFullWidth>
              <TxtLargeMediumDarkResp>{mainFromTo()}</TxtLargeMediumDarkResp>
              <TxtDefaultBrownResp>{affectedTrain.originDepartureDate}</TxtDefaultBrownResp>
            </FlexBetweenFullWidth>
          </AffectedJourney>

          {deviation.messageSummary && deviation.messageSummary.no != deviation.messageDescription.no && (
            <DeviationText>
              {currentLanguage === 'no' ? deviation.messageSummary.no : deviation.messageSummary.en}
            </DeviationText>
          )}
          <DeviationText>
            {currentLanguage === 'no' ? deviation.messageDescription.no : deviation.messageDescription.en}
          </DeviationText>

          <ReadMore href={`${opsTrafficUrl}${affectedTrain.serviceId}`} target="_blank" rel="noreferrer">
            {translate('READ_MORE')}
          </ReadMore>
        </FlexColumn>
      ) : (
        <FlexColumn>
          <CriticalHeader>
            <WarningIconWarning width={4.5} height={4.5} />
            <DeviationText>
              {currentLanguage === 'no' ? deviation.messageAlert?.no : deviation.messageAlert?.en}
            </DeviationText>
          </CriticalHeader>

          <CriticalDescription>
            {formatDescription(
              currentLanguage === 'no' ? deviation.messageDescription.no : deviation.messageDescription.en,
            ).map((str, index) => (
              <span key={index}>
                {str}
                <br />
              </span>
            ))}
          </CriticalDescription>
        </FlexColumn>
      )}
    </DeviationContainer>
  );
};
