/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { useApi } from 'hooks/use-api/useApi';
import { useSelector } from 'react-redux';
import { AppState } from 'store/index';
import { Constants } from 'utils/Constants';
import { BreadcrumbsPage } from 'components/new-design/layouts/BreadcrumbsPage';
import { SmallContainer, SmallTopContainer } from 'components/new-design/layouts/LayoutContainers';
import { IsTrainOnTimeLink } from 'elements/new-design/links/IsTrainOnTime';
import { SectionStationSearch } from 'components/section-station-search/SectionStationSearch';
import { DeviationsSection } from './DeviationsSection';
import { WarningIconWarning, InfoIconWarning, InfoIconInfo } from 'elements/new-design/icons/SymbolIcons';
import { SectionMessage } from 'elements/new-design/messages/SectionMessage';
import { ContainerBorderBoxShadow } from 'elements/new-design/Containers';
import { MarginBottom, MarginTop } from 'elements/distance/Margins';
import { CustomerServiceContactInfo } from 'elements/new-design/contacts/CustomerServiceContactInfo';
import { handleDateConversion } from 'utils/Helpers';

type DeviationsPageProps = {
  title: string;
};

const DeviationsList = styled.ul`
  list-style: none;
`;

const SearchContainer = styled(ContainerBorderBoxShadow)`
  padding: 2.4rem;
`;

const DeviationsState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR}`;

//TODO: Keep for now, in case more testing is needed.
// Mock data might cause some issues when switching languages. Real data seems to work just fine.

// const DeviationsResultTest = {
//   critical: [
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [],
//       affectedProductLineIds: [50],
//       fromDateTime: '2024-10-09T11:54:43Z',
//       hasReplacements: false,
//       isPlanned: false,
//       messageAlert: {
//         en: 'Et godstog har sporet av på Sørlandsbanen i nærheten av Vegå',
//         no: 'Et godstog har sporet av på Sørlandsbanen i nærheten av Vegå',
//       },
//       messageDescription: {
//         en: 'Arbeidet etter avsporingen er mer omfattende enn først antatt og sporet mellom Vegårshei og Gjerstad vil være stengt den kommende uka. Foreløpig prognose for åpning av strekningen er søndag 2. juni.\n\nBuss eller taxi vil kjøre fra berørte stasjoner på togets avgangstid.\n\nØnsker du å endre din reise eller har spørsmål kan du kontakte kundesenteret på telefon 61 25 80 00.\n\nFor ytterligere informasjon se\n\nhttps://go-aheadnordic.no/aktuelt/godstog-avsporet',
//         no: 'Arbeidet etter avsporingen er mer omfattende enn først antatt og sporet mellom Vegårshei og Gjerstad vil være stengt den kommende uka. Foreløpig prognose for åpning av strekningen er søndag 2. juni.\n\nBuss eller taxi vil kjøre fra berørte stasjoner på togets avgangstid.\n\nØnsker du å endre din reise eller har spørsmål kan du kontakte kundesenteret på telefon 61 25 80 00.\n\nFor ytterligere informasjon se\n\nhttps://go-aheadnordic.no/aktuelt/godstog-avsporet',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0838',
//       stationFrom: 'Vegårshei',
//       stationTo: 'Oslo S',
//       status: 'Opened',
//       untilDateTime: '2024-10-09T13:00:00Z',
//       updatedTime: '2024-10-09T11:54:43Z',
//       withDailyTimeSlot: false,
//     },
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [
//         {
//           destination: 'KBG',
//           destinationArrivalTime: '2025-10-09T22:25:00Z',
//           operatingDate: '2025-10-09T00:00:00Z',
//           origin: 'STV',
//           originDepartureTime: '2025-10-09T14:34:00Z',
//           productLineId: 50,
//           replacementsPresent: false,
//           serviceId: '716 2025-10-09',
//           serviceJourneyId: 'GOA:ServiceJourney:716-KVB_28937-R',
//           trainNumber: '716',
//         },
//       ],
//       affectedProductLineIds: [50],
//       fromDateTime: '2025-10-09T08:23:56Z',
//       hasReplacements: false,
//       isPlanned: false,
//       messageDescription: {
//         en: '2025 - Train is cancelled between Kristiansand and Oslo S due to lack of personal.\r\nWe are running rail replacement buses.',
//         no: '2025 - Toget er innstilt mellom Kristiansand og Oslo S pga. personellmangel.\r\nVi kjører buss for tog.',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0836',
//       stationFrom: 'Kristiansand',
//       stationTo: 'Oslo S',
//       status: 'Opened',
//       untilDateTime: '2025-10-09T22:25:00Z',
//       updatedTime: '2025-10-09T08:52:15Z',
//       withDailyTimeSlot: false,
//     },
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [
//         {
//           destination: 'KBG',
//           destinationArrivalTime: '2024-10-09T22:25:00Z',
//           operatingDate: '2024-10-09T00:00:00Z',
//           origin: 'STV',
//           originDepartureTime: '2024-10-09T14:34:00Z',
//           productLineId: 50,
//           replacementsPresent: false,
//           serviceId: '716 2024-10-09',
//           serviceJourneyId: 'GOA:ServiceJourney:716-KVB_28937-R',
//           trainNumber: '716',
//         },
//       ],
//       affectedProductLineIds: [50],
//       fromDateTime: '2024-10-09T08:23:56Z',
//       hasReplacements: false,
//       isPlanned: false,
//       messageDescription: {
//         en: 'Train is cancelled between Kristiansand and Oslo S due to lack of personal.\r\nWe are running rail replacement buses.',
//         no: 'Toget er innstilt mellom Kristiansand og Oslo S pga. personellmangel.\r\nVi kjører buss for tog.',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0836',
//       stationFrom: 'Kristiansand',
//       stationTo: 'Oslo S',
//       status: 'Opened',
//       untilDateTime: '2024-10-09T22:25:00Z',
//       updatedTime: '2024-10-09T08:52:15Z',
//       withDailyTimeSlot: false,
//     },
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [
//         {
//           destination: 'LYS',
//           destinationArrivalTime: '2024-10-09T22:25:00Z',
//           operatingDate: '2024-10-09T00:00:00Z',
//           origin: 'STV',
//           originDepartureTime: '2024-10-09T12:02:00Z',
//           productLineId: 50,
//           replacementsPresent: false,
//           serviceId: '716 2024-10-09',
//           serviceJourneyId: 'GOA:ServiceJourney:716-KVB_28937-R',
//           trainNumber: '716',
//         },
//       ],
//       affectedProductLineIds: [50],
//       fromDateTime: '2024-10-09T08:23:56Z',
//       hasReplacements: false,
//       isPlanned: false,
//       messageDescription: {
//         en: 'Train is cancelled between Kristiansand and Oslo S due to lack of personal.\r\nWe are running rail replacement buses.',
//         no: 'Toget er innstilt mellom Kristiansand og Oslo S pga. personellmangel.\r\nVi kjører buss for tog.',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0836',
//       stationFrom: 'Kristiansand',
//       stationTo: 'Oslo S',
//       status: 'Opened',
//       untilDateTime: '2024-10-09T12:02:00Z',
//       updatedTime: '2024-10-09T08:52:15Z',
//       withDailyTimeSlot: false,
//     },
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [
//         {
//           destination: 'LYS',
//           destinationArrivalTime: '2024-10-09T22:25:00Z',
//           operatingDate: '2024-10-10T00:00:00Z',
//           origin: 'STV',
//           originDepartureTime: '2024-10-09T04:01:00Z',
//           productLineId: 50,
//           replacementsPresent: false,
//           serviceId: '716 2024-10-09',
//           serviceJourneyId: 'GOA:ServiceJourney:716-KVB_28937-R',
//           trainNumber: '716',
//         },
//       ],
//       affectedProductLineIds: [50],
//       fromDateTime: '2024-10-09T08:23:56Z',
//       hasReplacements: false,
//       isPlanned: false,
//       messageDescription: {
//         en: 'Train is cancelled between Kristiansand and Oslo S due to lack of personal.\r\nWe are running rail replacement buses.',
//         no: 'Toget er innstilt mellom Kristiansand og Oslo S pga. personellmangel.\r\nVi kjører buss for tog.',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0836',
//       stationFrom: 'Kristiansand',
//       stationTo: 'Oslo S',
//       status: 'Opened',
//       untilDateTime: '2024-10-09T04:01:00Z',
//       updatedTime: '2024-10-09T08:52:15Z',
//       withDailyTimeSlot: false,
//     },
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [
//         {
//           destination: 'LYS',
//           destinationArrivalTime: '2024-10-09T22:25:00Z',
//           operatingDate: '2024-10-11T00:00:00Z',
//           origin: 'STV',
//           originDepartureTime: '2024-10-09T14:34:00Z',
//           productLineId: 50,
//           replacementsPresent: false,
//           serviceId: '716 2024-10-09',
//           serviceJourneyId: 'GOA:ServiceJourney:716-KVB_28937-R',
//           trainNumber: '716',
//         },
//       ],
//       affectedProductLineIds: [50],
//       fromDateTime: '2024-10-09T08:23:56Z',
//       hasReplacements: false,
//       isPlanned: false,
//       messageDescription: {
//         en: 'Train is cancelled between Kristiansand and Oslo S due to lack of personal.\r\nWe are running rail replacement buses.',
//         no: 'Toget er innstilt mellom Kristiansand og Oslo S pga. personellmangel.\r\nVi kjører buss for tog.',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0836',
//       stationFrom: 'Kristiansand',
//       stationTo: 'Oslo S',
//       status: 'Opened',
//       untilDateTime: '2024-10-07T22:25:00Z',
//       updatedTime: '2024-10-09T08:52:15Z',
//       withDailyTimeSlot: false,
//     },
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [
//         {
//           destination: 'LYS',
//           destinationArrivalTime: '2024-10-09T22:25:00Z',
//           operatingDate: '2024-10-09T00:00:00Z',
//           origin: 'STV',
//           originDepartureTime: '2024-10-09T14:34:00Z',
//           productLineId: 50,
//           replacementsPresent: false,
//           serviceId: '716 2024-10-09',
//           serviceJourneyId: 'GOA:ServiceJourney:716-KVB_28937-R',
//           trainNumber: '716',
//         },
//       ],
//       affectedProductLineIds: [50],
//       fromDateTime: '2024-10-09T08:23:56Z',
//       hasReplacements: false,
//       isPlanned: false,
//       messageDescription: {
//         en: 'Train is cancelled between Kristiansand and Oslo S due to lack of personal.\r\nWe are running rail replacement buses.',
//         no: 'Toget er innstilt mellom Kristiansand og Oslo S pga. personellmangel.\r\nVi kjører buss for tog.',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0836',
//       stationFrom: 'Kristiansand',
//       stationTo: 'Oslo S',
//       status: 'Opened',
//       untilDateTime: '2024-10-08T22:25:00Z',
//       updatedTime: '2024-10-09T08:52:15Z',
//       withDailyTimeSlot: false,
//     },
//   ],
//   futurePlanned: [
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [],
//       affectedProductLineIds: [50],
//       fromDateTime: '2024-12-26T10:15:00Z',
//       hasReplacements: true,
//       isPlanned: true,
//       messageDescription: {
//         en: 'Bane NOR is performing maintenance work. The stretch will therefore be closed during this period. Alternative transportation will be provided. Please check the travel planner for updated information.',
//         no: 'Bane NOR utfører vedlikeholdsarbeid. Strekningen vil derfor være stengt i denne perioden. Det settes opp alternativ transport. Se reiseplanleggeren for oppdatert informasjon.',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0839',
//       stationFrom: 'Stavanger',
//       stationTo: 'Egersund',
//       status: 'Opened',
//       untilDateTime: '2025-10-28T12:58:00Z',
//       updatedTime: '2024-10-09T11:59:42Z',
//       weekDays: {
//         en: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
//         no: ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag'],
//       },
//       withDailyTimeSlot: true,
//     },
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [],
//       affectedProductLineIds: [50],
//       fromDateTime: '2025-01-06T10:15:00Z',
//       hasReplacements: true,
//       isPlanned: true,
//       messageDescription: {
//         en: 'Bane NOR is performing maintenance work. The stretch will therefore be closed during this period. Alternative transportation will be provided. Please check the travel planner for updated information.',
//         no: 'Bane NOR utfører vedlikeholdsarbeid. Strekningen vil derfor være stengt i denne perioden. Det settes opp alternativ transport. Se reiseplanleggeren for oppdatert informasjon.',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0839',
//       stationFrom: 'Stavanger',
//       stationTo: 'Egersund',
//       status: 'Opened',
//       untilDateTime: '2025-10-28T12:58:00Z',
//       updatedTime: '2024-10-09T11:59:42Z',
//       weekDays: {
//         en: ['Monday'],
//         no: ['Mandag'],
//       },
//       withDailyTimeSlot: false,
//     },
//     {
//       affectedProductLines: ['Sørtoget'],
//       affectedTrainNumbers: [],
//       affectedTrainServices: [],
//       affectedProductLineIds: [50],
//       fromDateTime: '2024-12-29T10:15:00Z',
//       hasReplacements: true,
//       isPlanned: true,
//       messageDescription: {
//         en: 'Bane NOR is performing maintenance work. The stretch will therefore be closed during this period. Alternative transportation will be provided. Please check the travel planner for updated information.',
//         no: 'Bane NOR utfører vedlikeholdsarbeid. Strekningen vil derfor være stengt i denne perioden. Det settes opp alternativ transport. Se reiseplanleggeren for oppdatert informasjon.',
//       },
//       reportType: 'Incident',
//       situationId: 'AV24-0839',
//       stationFrom: 'Stavanger',
//       stationTo: 'Egersund',
//       status: 'Opened',
//       untilDateTime: '2025-10-28T12:58:00Z',
//       updatedTime: '2024-10-09T11:59:42Z',
//       weekDays: {
//         en: ['Monday'],
//         no: ['Mandag'],
//       },
//       withDailyTimeSlot: false,
//     },
//   ],
//   plannedOngoing: [
//     // {
//     //   affectedProductLines: ['Sørtoget'],
//     //   affectedProductLineIds: [50],
//     //   fromDateTime: '2024-07-30T01:30:00Z',
//     //   hasReplacements: true,
//     //   isPlanned: true,
//     //   messageDescription: {
//     //     en: 'Sørtoget: Bane NOR carries out maintenance work between Kristiansand and Gjerstad from 03:30 to 09:30 from July 30th to December 15th. The stretch will therefore be closed during this period and we will set up rail replacement buses. NB: For travelers to/from Nelaug and Vegårshei, a taxi must be ordered no later than 48 hours in advance for certain departures. Call our customer center on tel. 61 25 80 00 to book a taxi. See the travel planner for updated information.',
//     //     no: 'Sørtoget: Bane NOR utfører vedlikeholdsarbeid mellom Kristiansand og Gjerstad fra kl. 03:30 til 09:30 i perioden 30.07 til 15.12. Strekningen vil derfor være stengt i denne perioden og vi setter opp buss for tog mellom Kristiansand og Gjerstad for Sørtoget. NB: For reisende til/fra Nelaug og Vegårshei må taxi bestilles senest 48 timer før på enkelte avganger. Ring vårt kundesenter på tlf 61 25 80 00 for bestilling av taxi. Se reiseplanleggeren for oppdatert informasjon.',
//     //   },
//     //   situationId: 'AV24-0534',
//     //   stationFrom: 'Kristiansand',
//     //   stationTo: 'Gjerstad',
//     //   status: 'Opened',
//     //   untilDateTime: '2024-12-15T08:30:00Z',
//     //   updatedTime: '2024-10-01T13:39:46Z',
//     //   weekDays: {
//     //     en: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
//     //     no: ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag'],
//     //   },
//     //   withDailyTimeSlot: true,
//     // },
//   ],
// };

export const DeviationsPage = ({ title }: DeviationsPageProps): JSX.Element => {
  const criticalDeviations = useSelector((state: AppState) => state.deviations);
  const [allCriticalDeviations, setAllCriticalDeviations] = useState([]);
  const { translate } = useI18n();
  const { API_CALLS, requestGoOps } = useApi();
  const [plannedDeviations, setPlannedDeviations] = useState(null);
  const [hasDeviations, setHasDeviations] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [state, setState] = useState<typeof DeviationsState>(Constants.LOADING);
  const [locations, setLocations] = useState([]);

  const fetchDeviations = async () => {
    try {
      const { data } = await requestGoOps(API_CALLS.GET_ALL_GOOPS_DEVIATIONS);
      try {
        // locations list, used in handleGetStationName, is used only for incidents
        // that has affected trains AND if property deviation.stationFrom does not exist.
        const { data: response } = await requestGoOps(API_CALLS.LOCATIONS);
        setLocations(response.result);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Could not get locations: ${e}`);
      }

      handleDateConversion(data.result.critical);
      handleDateConversion(data.result.futurePlanned);
      handleDateConversion(data.result.plannedOngoing);

      if (data.result.critical.length != 0) {
        const nonDuplicates = data.result.critical.reduce((result, curr) => {
          if (!criticalDeviations.find((c) => c.situationId == curr.situationId)) {
            result.push(curr);
          }
          return result;
        }, []);
        setAllCriticalDeviations(criticalDeviations.concat(nonDuplicates));
      }

      setPlannedDeviations(data.result);

      setState(Constants.IDLE);
    } catch (error) {
      setState(Constants.ERROR);
    }
  };

  const handleGetStationName = (stationNameShorthand: string) => {
    const st = locations.find((item) => item.locationId === stationNameShorthand);
    if (!st) {
      // eslint-disable-next-line no-console
      console.warn(
        `Missing station shorthans id ${stationNameShorthand} in locations. Got ${locations.length} locations listed.`,
      );
      return stationNameShorthand;
    }
    return st.locationName;
  };

  useEffect(() => {
    fetchDeviations();
  }, []);

  useEffect(() => {
    if (plannedDeviations) {
      setHasDeviations(
        Boolean(criticalDeviations.length) ||
          Boolean(plannedDeviations.plannedOngoing?.length) ||
          Boolean(plannedDeviations.futurePlanned?.length),
      );
    }
  }, [criticalDeviations, plannedDeviations]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (locations.length) console.warn(`Got ${locations.length} locations listed.`);
  }, [locations]);

  // useEffect(() => {
  //   if (plannedDeviations) {
  //     setHasDeviations(
  //       Boolean(DeviationsResultTest.critical.length) ||
  //         Boolean(DeviationsResultTest.plannedOngoing?.length) ||
  //         Boolean(DeviationsResultTest.futurePlanned?.length),
  //     );
  //   }
  // }, [DeviationsResultTest.critical, plannedDeviations]);

  return (
    <BreadcrumbsPage title={title} loading={false}>
      <SmallContainer>
        <IsTrainOnTimeLink type="button" expanded={expanded} onButtonClick={() => setExpanded(!expanded)} />
        {expanded && (
          <SmallTopContainer>
            <SearchContainer>
              <SectionStationSearch
                small="100%"
                medium="100%"
                large="100%"
                displaySwap={false}
                displaySubmit={true}
                redirectUrl={Constants.ROUTE_JOURNEY}
              />
            </SearchContainer>
          </SmallTopContainer>
        )}

        {state === Constants.ERROR && (
          <MarginTop margin={3.6}>
            <SectionMessage
              type={Constants.ERROR_MESSAGE}
              title={translate('GENERAL_ERROR_HEADLINE')}
              text={translate('REQUEST_DEVIATIONS_ERROR')}
            >
              <MarginBottom margin={1.6} />
              <CustomerServiceContactInfo />
            </SectionMessage>
          </MarginTop>
        )}
        {state === Constants.IDLE && (
          <>
            {!hasDeviations && (
              <MarginTop margin={3.6}>
                <SectionMessage
                  type={Constants.INFO_MESSAGE}
                  title={translate('NO_DEVIATIONS_HEADLINE')}
                  text={translate('NO_DEVIATIONS_TEXT')}
                />
              </MarginTop>
            )}
            {hasDeviations && (
              <DeviationsList>
                {/* {Boolean(true) && (
                  <DeviationsSection
                    title={translate('CRITICAL_DEVIATIONS')}
                    deviations={DeviationsResultTest.critical}
                    icon={<WarningIconWarning width={4.5} height={4.5} />}
                    handleGetStationName={handleGetStationName}
                  />
                )} */}
                {Boolean(allCriticalDeviations && allCriticalDeviations.length != 0) && (
                  <DeviationsSection
                    title={translate('CRITICAL_DEVIATIONS')}
                    deviations={allCriticalDeviations}
                    icon={<WarningIconWarning width={4.5} height={4.5} />}
                    handleGetStationName={handleGetStationName}
                  />
                )}
                {Boolean(plannedDeviations.plannedOngoing?.length) && (
                  <DeviationsSection
                    title={translate('ON_GOING_WORK')}
                    deviations={plannedDeviations.plannedOngoing}
                    icon={<InfoIconWarning width={4.5} height={4.5} />}
                    handleGetStationName={handleGetStationName}
                  />
                )}
                {Boolean(plannedDeviations.futurePlanned?.length) && (
                  <DeviationsSection
                    title={translate('PLANNED_WORK')}
                    deviations={plannedDeviations.futurePlanned}
                    icon={<InfoIconInfo width={4.5} height={4.5} />}
                    handleGetStationName={handleGetStationName}
                  />
                )}
              </DeviationsList>
            )}
          </>
        )}
      </SmallContainer>
    </BreadcrumbsPage>
  );
};
