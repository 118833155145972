const colors = {
  tuna: '#353649',
  white: '#ffffff',
  valencia: '#d43f51',
  polar: '#ebf5fa',
  regentBlue: '#b1dbe8',
  sunglow: '#ffd431',
  bajawhite: '#fff8d1',
  tropicalblue: '#bde1f4',
  chathamBlue: '#12576f',
  iron: '#dadde0',
  mako: '#404548',
  carouselPink: '#fcedf1',
  tundora: '#808080',
  sushi: '#8dc63f',
  endeavour: '#0054A6',
  wildsand: '#F5F5F5',
  pomegranate: '#ef3e2e',
  naturalGray: '#8e8c80',
  linkWater: '#ecf5fa',
  catskillWhite: '#ecf1f5',
  saltBox: '#746f76',
  saltBox_darker: '#646a66',
  alizarinCrimson: '#e02128',
  atlantis: '#8cc83c',
  backdrop: 'rgba(53, 54, 73, 0.1)',
  boxshadow: '0 0 99px 0 rgba(53, 54, 73, 0.2)',
  borderBoxshadow: '0 0 5px 0 rgb(0 0 0 / 15%)',
};

export default {
  txtColDark: colors.tuna,
  txtColMedium: colors.mako,
  txtColLight: colors.white,
  txtColError: colors.valencia,
  txtColorAccent: colors.chathamBlue,
  txtColorGanTrainGreen: colors.sushi,
  txtColorGanTrainBlue: colors.endeavour,
  txtColorGanTrainRed: colors.pomegranate,
  txtColBrown: colors.naturalGray,
  txtColDarkGrey: colors.saltBox_darker,
  borderDark: colors.tuna,
  borderError: colors.valencia,
  borderLight: colors.white,
  borderTable: colors.iron,
  borderReadonly: colors.tundora,
  borderInfo: colors.tropicalblue,
  bgPrimary: colors.white,
  bgDarkLight: colors.polar,
  bgDark: colors.tuna,
  bgReadonly: colors.tundora,
  bgInfo: colors.polar,
  bgGrey: colors.wildsand,
  bgLightTeal: colors.linkWater,
  bgLightBlue: colors.catskillWhite,
  bgBlue: colors.endeavour,
  bgRed: colors.alizarinCrimson,
  bgGreen: colors.atlantis,
  focus: colors.regentBlue,
  warningDark: colors.sunglow,
  warningLight: colors.bajawhite,
  errorDark: colors.valencia,
  errorLight: colors.carouselPink,
  infoDark: colors.tropicalblue,
  infoLight: colors.polar,
  backdrop: colors.backdrop,
  boxshadow: colors.boxshadow,
  borderBoxshadow: colors.borderBoxshadow,
};
