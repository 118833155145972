import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { Config } from 'utils/Config';
import { TxtSmallBoldDarkResp } from 'elements/new-design/Typography';
import { BusIconDark } from 'elements/new-design/icons/SymbolIcons';
import { FlexEnd } from 'elements/containers/Containers';
import { A } from 'hookrouter';

type BusForTrainProps = {
  deviationId: string;
};

const Link = styled(A)`
  display: flex;
  align-items: center;

  span {
    border-bottom: ${(props) => props.theme.newconstants.borderDarkThin};
  }

  :hover,
  :focus-visible {
    box-shadow: none;
    margin-bottom: -1px;

    span {
      border-bottom: ${(props) => props.theme.newconstants.borderDarkThick};
    }
  }
`;
const BUS_FOR_TRAIN_URL = Config().busForTrainUrl;

export const BusForTrain = ({ deviationId }: BusForTrainProps): JSX.Element => {
  const { translate } = useI18n();
  return (
    <FlexEnd>
      <Link
        href={`${BUS_FOR_TRAIN_URL}${deviationId}`}
        target="_blank"
        aria-label={`${translate('READ_MORE')} ${translate('BUS_FOR_TRAIN')}`}
      >
        <BusIconDark width={3.8} height={3.8} />
        <TxtSmallBoldDarkResp>{translate('BUS_FOR_TRAIN')}</TxtSmallBoldDarkResp>
      </Link>
    </FlexEnd>
  );
};
