import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from 'styles/new-design/colors';
import { useI18n } from 'utils/i18n/usei18n';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Config } from 'utils/Config';
import { RTVStation, RTVTrain, RTVTrainMessage } from 'types/RTVTypes';
import { TextField } from 'components/new-design/formfields/TextField';
import { useApi } from 'hooks/use-api/useApi';
import {
  ARENDAL_LINENUMBER,
  AbsoluteHorizontalBorder,
  MapRow,
  StopPlaceName,
  StopPlaceNameContainer,
  handleGetTrains,
  handleGoingToBaneNor,
  handleTrains,
} from './RealTimeViewUtils';
import { Loader } from 'elements/loader/Loader';
import ArendalLine from './ArendalLine';
import { TxtSmallMediumDarkResp } from 'elements/new-design/Typography';
import { FlexEnd } from 'elements/containers/Containers';
import { breakPoints } from 'styles/new-design/breakpoints';
import { ButtonAccentPrimary } from 'elements/buttons/Buttons';

const RTVMain = styled.div`
  position: relative;
`;

const MapviewContainer = styled.div`
  display: flex;
  width: 38rem;
  margin-top: 2rem;
  padding: 1rem;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.small} {
    width: 45rem;
  }
  @media ${(props) => props.theme.breakpoints.medium} {
    width: 70rem;
  }
`;

const TrainsSection = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  flex-flow: wrap;
  z-index: 1;
`;

const AutomcompleteForm = styled.form`
  padding: 1rem;

  #SingleStationSearch {
    background-color: ${() => colors.bgPrimary};
  }
`;

const HiddenStationDot = styled(TxtSmallMediumDarkResp)`
  display: flex;
  border: ${(props) => props.theme.newconstants.borderDarkThick};
  border-radius: 1rem;
  height: 1rem;
  width: 1rem;
  background-color: ${() => colors.bgDark};
`;

const SOCKET_URL = Config().rtvConnectionUrl;

const RealTimeView = () => {
  const { API_CALLS, requestGoOps } = useApi();
  const { translate } = useI18n();
  const [connection, setConnection] = useState(null);
  const [trains, setTrains] = useState([]);
  const [searchedStation, setSearchedStation] = useState('');
  const [loading, setLoading] = useState(true);
  const [isMouseOverTrain, setIsMouseOverTrain] = useState('');
  const [stations, setStations] = useState([]);
  const [hiddenStationHoverId, setHiddenStationHoverId] = useState('');
  const [isStationsReversed, setIsStationsReversed] = useState(false);
  const stationNames = stations.reduce((acc, curr) => {
    acc.push(curr.locationName);
    if (curr.locationName === 'Nelaug') curr.locations.forEach((station: RTVStation) => acc.push(station.locationName));

    return acc;
  }, []);

  const autocompleteMatch = (input: string) => {
    if (input === '') return [];

    const options = stationNames.filter((name) => {
      if (name.toLowerCase().includes(input.toLowerCase())) return name;
    });

    if (options.length === 0) options.unshift(translate('NO_HIT'));

    return options;
  };

  const listenForMessages = async () => {
    let temp = [...trains];
    await connection
      .start()
      .then(() => {
        connection.on('trains', (messages: RTVTrainMessage[]) => {
          messages.forEach((item) => {
            if (item.state === 'Modified') {
              const index = temp.findIndex((train) => train.serviceId === item.key);
              if (index === -1) temp.push(item.value);
              else temp[index] = item.value;
            }

            if (item.state === 'Removed') temp = temp.filter((train) => train.serviceId !== item.key);
          });

          setTrains(temp);
        });
      })
      .catch(() => {
        setTrains([]);
        connection.stop().then(() => setConnection(null));
      });
  };

  const openConnection = async () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(SOCKET_URL)
      .configureLogging(LogLevel.Warning)
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  };

  useEffect(() => {
    if (connection) listenForMessages();
  }, [connection]);

  const init = async () => {
    setLoading(true);
    try {
      const { data: trainsData } = await requestGoOps(API_CALLS.GET_TRAINS);
      const { data: rtvLocationsData } = await requestGoOps(API_CALLS.GET_RTV_STATIONS);
      if (trainsData && rtvLocationsData) {
        setTrains(trainsData.result);
        setStations(rtvLocationsData.result);
        openConnection();
        setLoading(false);
      }
    } catch (error) {
      setTrains([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    init();

    return () => {
      setTrains([]);
      connection?.stop().then(() => setConnection(null));
    };
  }, []);

  const handleMouseOver = (trainNumber: string) => isMouseOverTrain === trainNumber;

  useEffect(() => {
    if (stationNames.includes(searchedStation))
      document.getElementById(searchedStation).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [searchedStation]);

  const handleDisplayingTrains = (trainsToDisplay: RTVTrain[]) => (
    <TrainsSection>
      {handleTrains(trainsToDisplay, handleMouseOver, setIsMouseOverTrain, translate, isStationsReversed)}
    </TrainsSection>
  );

  const arendalTrainsToDisplay = trains.filter((train) => train.lineNumber === ARENDAL_LINENUMBER);

  const handleDisplayMap = () => {
    const locations = stations.filter((train) => train.locationName === 'Nelaug')[0].locations;

    return stations.map((station, stationIndex) => {
      const { locationName, locationId, isBig, isDisplay } = station;
      const trainsToDisplay = handleGetTrains(
        trains.filter((train) => train.lineNumber !== ARENDAL_LINENUMBER),
        locationId,
      );

      return (
        <React.Fragment key={stationIndex}>
          {locationName !== 'Nelaug' && (
            <MapRow>
              {isDisplay ? (
                <StopPlaceNameContainer>
                  <StopPlaceName id={locationName} isBig={isBig} onClick={() => handleGoingToBaneNor(locationId)}>
                    {locationName}
                  </StopPlaceName>
                </StopPlaceNameContainer>
              ) : (
                <StopPlaceNameContainer>
                  {hiddenStationHoverId === locationId ? (
                    <TxtSmallMediumDarkResp onMouseLeave={() => setHiddenStationHoverId('')}>
                      {locationName}
                    </TxtSmallMediumDarkResp>
                  ) : (
                    <FlexEnd>
                      <HiddenStationDot
                        id={locationName}
                        onMouseEnter={() => setHiddenStationHoverId(locationId)}
                        onMouseLeave={() => setHiddenStationHoverId('')}
                      />
                    </FlexEnd>
                  )}
                </StopPlaceNameContainer>
              )}

              {handleDisplayingTrains(trainsToDisplay)}
            </MapRow>
          )}

          {locationName === 'Nelaug' && screen.width > Number(breakPoints.large) && (
            <MapRow>
              <ArendalLine
                locations={locations}
                trainsToDisplay={arendalTrainsToDisplay}
                handleDisplayingTrains={handleDisplayingTrains}
                isSmallScreen={false}
              />
              <StopPlaceNameContainer>
                <StopPlaceName id={locationName} isBig={isBig} onClick={() => handleGoingToBaneNor(locationId)}>
                  {locationName}
                </StopPlaceName>
              </StopPlaceNameContainer>

              {handleDisplayingTrains(trainsToDisplay)}
            </MapRow>
          )}

          {stationIndex === stations.length - 1 && screen.width <= Number(breakPoints.large) && (
            <ArendalLine
              locations={locations}
              trainsToDisplay={arendalTrainsToDisplay}
              handleDisplayingTrains={handleDisplayingTrains}
              isSmallScreen={true}
            />
          )}
        </React.Fragment>
      );
    });
  };

  const handleToggleDirection = () => {
    setIsStationsReversed(!isStationsReversed);
    setStations([...stations].reverse());
  };

  return (
    <RTVMain>
      {loading && <Loader />}
      {!loading && (
        <>
          <AutomcompleteForm autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <TextField
              type="GANsearch"
              name="SingleStationSearch"
              id="SingleStationSearch"
              label="STATION"
              value={searchedStation || ''}
              error=""
              onFieldChange={(e) => setSearchedStation(e.currentTarget.value)}
              onFieldBlur={() => false}
              onClearButtonClick={() => setSearchedStation('')}
              selectOptions={autocompleteMatch(searchedStation)}
              onInputChange={(e) => setSearchedStation(e.target.innerText)}
              hideResults={stationNames.includes(searchedStation) || searchedStation === ''}
            />
          </AutomcompleteForm>

          <ButtonAccentPrimary onClick={() => handleToggleDirection()}>
            {translate('TOGGLE_DIRECTION')}
          </ButtonAccentPrimary>

          <AbsoluteHorizontalBorder />
          <MapviewContainer>{stations && handleDisplayMap()}</MapviewContainer>
        </>
      )}
    </RTVMain>
  );
};

export default RealTimeView;
