import { Order } from 'types/OrderTypes';
import { GOADate } from 'utils/date/GOADate';
import { constructPassengers, constructSupplements } from 'utils/Helpers';
import {
  Journey_Get,
  Journey_GetLaterDeparturesForLeg,
  Journey_GetEarlierDeparturesForLeg,
  Journey_ReplaceLegInJourney,
  Journey_GetJourneyPlan,
} from 'apiV2/Journey';
import { Offer_GetTripPatternInfo } from 'apiV2/Offer';

export const useJourneyApi = (token: string, order: Order) => {
  const journeyGet = async (data: { isDeparture: boolean; date: GOADate }) =>
    await Journey_Get({
      authorization: token ? 'Bearer ' + token : null,
      fromId: data.isDeparture ? order.from.id : order.to.id,
      toId: data.isDeparture ? order.to.id : order.from.id,
      isDepartureTime: true,
      dateTimeUtc: data.date ? data.date : data.isDeparture ? order.departureDate : order.returnDate,
      passenger: constructPassengers(order.passengers),
      luggage: constructSupplements(order.supplements),
      includeSupplementProducts: true,
      includeAdditionalOffers: true,
      includePlannedCancellations: true,
      includeRealtimeCancellations: true,
      includeDeparted: true,
      feat: ['SoveProduct'],
    });

  const getLaterDeparturesForLeg = async (data: { id: string; index: number }) =>
    await Journey_GetLaterDeparturesForLeg(data.id, data.index);

  const getEarlierDeparturesForLeg = async (data: { id: string; index: number }) =>
    await Journey_GetEarlierDeparturesForLeg(data.id, data.index);

  type ReplaceType = {
    journeyId: string;
    index: number;
    fromId: string;
    toId: string;
    serviceJourneyId: string;
    departureTime: GOADate;
  };

  const getJourneyPlan = async (data: { isDeparture: boolean; date: GOADate }) => {
    return await Journey_GetJourneyPlan({
      fromId: data.isDeparture ? order.from.id : order.to.id,
      toId: data.isDeparture ? order.to.id : order.from.id,
      isDepartureTime: true,
      dateTimeUtc: data.date ? data.date : data.isDeparture ? order.departureDate : order.returnDate,
      numTripPatterns: 30,
    });
  };

  const replaceLegInJourney = async (data: ReplaceType) => {
    const options = {
      fromId: data.fromId,
      toId: data.toId,
      serviceJourneyId: data.serviceJourneyId,
      departureTime: data.departureTime,
      passenger: constructPassengers(order.passengers),
      luggage: constructSupplements(order.supplements),
    };
    return await Journey_ReplaceLegInJourney(data.journeyId, data.index, options);
  };

  const getOffers = async (data: { isDeparture: boolean; compartments: number }) => {
    const options = {
      body: {
        passenger: constructPassengers(order.passengers),
        luggage: constructSupplements(order.supplements),
        legs: data.isDeparture ? order.journey.selectedJourney.legs : order.returnJourney.selectedJourney.legs,
        numCompartments: data.compartments,
        mockSleeperSupplement: false,
        includeSleeperProduct: true,
      },
    };
    return await Offer_GetTripPatternInfo(options);
  };

  return {
    journeyGet,
    getLaterDeparturesForLeg,
    getEarlierDeparturesForLeg,
    replaceLegInJourney,
    getJourneyPlan,
    getOffers,
  };
};
