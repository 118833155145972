import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import colors from 'styles/new-design/colors';
import { RTVTrain } from 'types/RTVTypes';
import {
  TxtDefaultMediumDarkResp,
  TxtDefaultRegularDarkResp,
  fontSizeMediumResp,
  fontSizeSmallResp,
} from 'elements/new-design/Typography';
import TrainUp from 'assets/svg/new-design/caret_up.svg';
import TrainDown from 'assets/svg/new-design/caret_down.svg';
import TrainUpWaiting from 'assets/svg/new-design/caret_up_not_moving.svg';
import TrainDownWaiting from 'assets/svg/new-design/caret_down_not_moving.svg';
import { MarginLeft } from 'elements/distance/Margins';
import { Icon } from 'elements/icon/Icon';
import { FlexRow } from 'elements/containers/Containers';

type StyleProps = {
  isWhiteTxt?: boolean;
  isBig?: boolean;
};

const GanLine = styled.span`
  padding: 0.5rem;
  background-color: ${(props) => props.color};
  border-radius: 0.2rem;
`;

const TrainName = styled(TxtDefaultRegularDarkResp)<StyleProps>`
  line-height: 1;
  color: ${(props) => (props.isWhiteTxt ? colors.txtColLight : colors.txtColDark)};
`;

const Train = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: 0.5rem 1rem;
  padding: 0.6rem 1rem;
  background-color: ${(props) => props.color};
`;
const TrainDirectionIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`;

export const AbsoluteHorizontalBorder = styled.span`
  position: absolute;
  border-right: 2px dotted black;
  left: 19rem;
  height: 97.5%;
  bottom: 2rem;

  @media ${(props) => props.theme.breakpoints.small} {
    left: 27rem;
  }
`;

export const StopPlaceNameContainer = styled.div`
  align-self: center;
  width: 17rem;
  text-align: right;
  margin: 0.5rem 2rem 0.5rem 0rem;

  @media ${(props) => props.theme.breakpoints.small} {
    width: 25rem;
  }
`;

export const StopPlaceName = styled(TxtDefaultMediumDarkResp)<StyleProps>`
  cursor: pointer;
  :focus-visible,
  :hover {
    border-bottom: ${(props) => props.theme.newconstants.borderDarkThick};
  }
  ${(props) => (props.isBig ? `${fontSizeMediumResp}` : `${fontSizeSmallResp}`)};
`;

export const MapRow = styled(FlexRow)`
  position: relative;
  padding: 1rem 0;
`;

export const ARENDAL_LINENUMBER = 'R50';
export const TRAIN_CANCELLED = 'Y';
export const TRAIN_NOT_CANCELLED = 'N';
export const TRAIN_PARTIALLY_CANCELLED = 'P';

export const handleGoingToBaneNor = (stationKey: string) =>
  window.open(
    `http://rtd.opm.jbv.no:8080/web_client/std?station=${stationKey}&header=yes&page=1&notice=no&layout=landscape&content=departure`,
  );

export const handleGetTrains = (trains: RTVTrain[], locationId: string) => {
  let temp = [...trains];
  const isGanTrain = (train: RTVTrain) => train.lineNumber === 'L5' || train.lineNumber === 'F5';
  const isTrainCancelled = (train: RTVTrain) =>
    train.trainCancelStatus === TRAIN_NOT_CANCELLED
      ? false
      : train.trainCancelStatus === TRAIN_CANCELLED
      ? true
      : train.trainCancelStatus === TRAIN_PARTIALLY_CANCELLED && !train.isCanceled
      ? false
      : true;

  temp = temp.filter((train) => train.position === locationId && !isTrainCancelled(train));

  temp.sort((x, y) => (isGanTrain(x) ? -1 : isGanTrain(y) ? 1 : 0));

  return temp;
};

export const handleTrainDirectionIcon = (train: RTVTrain, isStationsReversed: boolean) => {
  const isTrainNumberEven = Number(train.trainNumber) % 2 === 0;
  const isArendalLineTrain = train.lineNumber === ARENDAL_LINENUMBER;

  if (isArendalLineTrain && !isTrainNumberEven && train.actualDepartureTime) return TrainDown;
  if (isArendalLineTrain && !isTrainNumberEven && !train.actualDepartureTime) return TrainDownWaiting;
  if (isArendalLineTrain && isTrainNumberEven && train.actualDepartureTime) return TrainUp;
  if (isArendalLineTrain && isTrainNumberEven && !train.actualDepartureTime) return TrainUpWaiting;

  if (!isTrainNumberEven && train.actualDepartureTime) return !isStationsReversed ? TrainUp : TrainDown;
  if (!isTrainNumberEven && !train.actualDepartureTime) return !isStationsReversed ? TrainUpWaiting : TrainDownWaiting;
  if (isTrainNumberEven && train.actualDepartureTime) return !isStationsReversed ? TrainDown : TrainUp;
  if (isTrainNumberEven && !train.actualDepartureTime) return !isStationsReversed ? TrainDownWaiting : TrainUpWaiting;
};

export const handleTrainDeviation = (train: RTVTrain) => {
  const delayInMinutes = train.departureDelay !== undefined ? train.departureDelay : train.arrivalDelay;
  const delayMessage =
    delayInMinutes !== undefined && delayInMinutes !== 0 && `(${delayInMinutes < 0 ? '' : '+'}${delayInMinutes}min)`;

  return {
    deviationColor:
      delayInMinutes < 0
        ? colors.bgGrey
        : delayInMinutes >= 1
        ? colors.warningLight
        : delayInMinutes >= 6
        ? colors.errorLight
        : colors.bgGrey,
    delayMsg: delayMessage,
  };
};

export const handleTrainName = (train: RTVTrain, isMouseOverTrain: boolean, translate: (key: string) => string) => {
  if (train.lineNumber === 'L5') {
    return (
      <GanLine color={colors.txtColorGanTrainGreen}>
        <TrainName>{isMouseOverTrain ? train.trainNumber : train.lineNumber}</TrainName>
      </GanLine>
    );
  }

  if (train.lineNumber === 'F5') {
    return (
      <GanLine color={colors.txtColorGanTrainBlue}>
        <TrainName isWhiteTxt>{isMouseOverTrain ? train.trainNumber : train.lineNumber}</TrainName>
      </GanLine>
    );
  }

  if (train.lineNumber === ARENDAL_LINENUMBER) {
    return (
      <GanLine color={colors.txtColorGanTrainRed}>
        <TrainName isWhiteTxt>{isMouseOverTrain ? train.trainNumber : train.lineNumber}</TrainName>
      </GanLine>
    );
  }

  if (!train.lineNumber)
    return (
      <TxtDefaultRegularDarkResp>
        {isMouseOverTrain ? train.trainNumber : translate('FREIGHT_TRAIN')}
      </TxtDefaultRegularDarkResp>
    );

  return <TrainName>{isMouseOverTrain ? train.trainNumber : train.lineNumber}</TrainName>;
};

export const handleTrains = (
  trainsToDisplay: RTVTrain[],
  handleMouseOver: (trainNumber: string) => boolean,
  setIsMouseOverTrain: Dispatch<SetStateAction<string>>,
  translate: any,
  isStationsReversed: boolean,
) => {
  return trainsToDisplay.map((train: RTVTrain, trainIndex: number) => {
    const { deviationColor, delayMsg } = handleTrainDeviation(train);
    const mouseOver = handleMouseOver(train.trainNumber);

    return (
      <Train
        key={trainIndex}
        color={train.lineNumber ? deviationColor : colors.bgGrey}
        onMouseEnter={() => setIsMouseOverTrain(train.trainNumber)}
        onMouseLeave={() => setIsMouseOverTrain('')}
      >
        {handleTrainDirectionIcon(train, isStationsReversed) !== undefined && (
          <TrainDirectionIcon icon={handleTrainDirectionIcon(train, isStationsReversed)} />
        )}
        {handleTrainName(train, mouseOver, translate)}
        {train.lineNumber && delayMsg !== '' && (
          <MarginLeft margin={0.5}>
            <TxtDefaultMediumDarkResp>{delayMsg}</TxtDefaultMediumDarkResp>
          </MarginLeft>
        )}
      </Train>
    );
  });
};
