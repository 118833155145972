import React from 'react';
import styled from 'styled-components';
import ExclamationIcon from 'assets/svg/exclamation-mark-dark.svg';
import CheckmarkAccentIcon from 'assets/svg/checkmark-accent.svg';
import BankCardIcon from 'assets/svg/bank-card.svg';
import TrainIcon from 'assets/svg/train.svg';
import BusIcon from 'assets/svg/bus.svg';
import BusGreyIcon from 'assets/svg/new-design/bus-grey.svg';
import ChevronGreyRightIcon from 'assets/svg/chevron-right-grey.svg';
import ChevronWhiteRightIcon from 'assets/svg/chevron-right-white.svg';
import ChevronDarkIcon from 'assets/svg/chevron-down-dark.svg';
import InfoIconNoBg from 'assets/svg/info.svg';
import InfoIconWithBg from 'assets/svg/info-grey.svg';
import BusStop from 'assets/svg/bus-stop.svg';
import { FlexRow } from 'elements/containers/Containers';

// Types
// =========================

export type IconProps = {
  width?: number;
  height?: number;
  icon?: string;
  color?: string;
  smallDevice?: boolean;
};

// Elements
// =========================

const IconWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const StyledIcon = styled.div`
  ${(_: IconProps) => ''}
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(${(props) => props.icon}) center / contain no-repeat;
`;

// Component
// =========================

export const Icon = ({ icon, ...props }: IconProps): JSX.Element => {
  return (
    <IconWrapper {...props}>
      <StyledIcon icon={icon} />
    </IconWrapper>
  );
};

export const CardIcon = styled.svg`
  width: 2.6rem;
  height: 1.9rem;
  background: url(${BankCardIcon}) no-repeat left center;
`;

const TransportIcon = styled.svg`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.3rem;
`;

export const TrainIconBgGrey = styled(TransportIcon)`
  background: url(${TrainIcon}) no-repeat left center;
`;

export const BusIconBgGrey = styled(TransportIcon)`
  background: url(${BusIcon}) no-repeat left center;
`;

export const BusGreyIconBgTran = styled(TransportIcon)`
  background: url(${BusGreyIcon}) no-repeat left center;
`;

export const BusStopIcon = styled.svg`
  height: 1.7rem;
  width: 1.7rem;
  background: url(${BusStop}) no-repeat left center;
`;

type IconStyleProps = {
  width?: number;
  height?: number;
  color?: string;
  leftRightMargin: number;
};

const Chevron = styled.div`
  ${(_: IconStyleProps) => ''}
  height: ${(props) => (props.height ? `${props.height}rem` : '1.44rem')};
  width: ${(props) => (props.width ? `${props.width}rem` : ' 1.5rem')};
  margin-right: ${(props) => (props.leftRightMargin ? `${props.leftRightMargin}rem` : ' 0rem')};
  margin-left: ${(props) => (props.leftRightMargin ? `${props.leftRightMargin}rem` : ' 0rem')};
`;

const ChevronGreyRight = styled(Chevron)`
  background: url(${ChevronGreyRightIcon}) no-repeat center;
`;

const ChevronWhiteRight = styled(Chevron)`
  background: url(${ChevronWhiteRightIcon}) no-repeat center;
`;

const ChevronDarkRight = styled(Chevron)`
  background: url(${ChevronDarkIcon}) no-repeat center;
  transform: rotate(-90deg);
`;

export const ChevronRight = ({ ...props }: IconStyleProps) => {
  if (props.color === 'grey') return <ChevronGreyRight {...props} />;
  if (props.color === 'white') return <ChevronWhiteRight {...props} />;
  if (props.color === 'dark') return <ChevronDarkRight {...props} />;
};

export const CheckmarkAccent = styled.div`
  ${(_: IconStyleProps) => ''}
  height: ${(props) => (props.height ? `${props.height}rem` : '1.6rem')};
  width: ${(props) => (props.width ? `${props.width}rem` : ' 1.6rem')};
  color: ${(props) => props.theme.colors.accentPrimary};
  background: url(${CheckmarkAccentIcon}) no-repeat center;
`;

const InfoNoBg = styled.svg`
  border: 0.1rem solid ${(props) => props.theme.colors.mako};
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  flex-shrink: 0;
  background: url(${InfoIconNoBg}) no-repeat left center;
  background-size: 1.5rem 0.9rem;
`;

const InfoWithBg = styled.svg`
  width: 1.5rem;
  height: 2rem;
  flex-shrink: 0;
  background: url(${InfoIconWithBg}) no-repeat left center;
`;

export const InfoCircle = ({ ...props }: IconProps) => {
  if (props.color === 'grey') return <InfoNoBg />;
};

export const InfoCircleWithBg = () => <InfoWithBg />;

//DEVIATION ICONS

const SingleDeviationContainer = styled(FlexRow).attrs((props) => ({
  className: `SingleDeviationContainer ${props.className}`,
}))`
  width: 2rem;
  height: 1.6rem;
  position: relative;
`;

const DarkYellowCircle = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  top: 0;
  left: 0.4rem;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.deviationIcon};
`;

const DeviationIconContainer = styled(DarkYellowCircle).attrs((props) => ({
  className: `DeviationIconContainer ${props.className}`,
}))`
  left: 0;
  margin-right: 1rem;
  margin-bottom: 0.2rem;
  background: ${(props) => props.theme.colors.deviationIcon} url(${ExclamationIcon}) no-repeat center center;
  background-size: 1.2rem 1.2rem;
  z-index: ${(props) => props.theme.constants.zIndexMedium};
`;

export const DeviationIcon = (): JSX.Element => (
  <SingleDeviationContainer>
    <DeviationIconContainer />
  </SingleDeviationContainer>
);

const InfoIconContainer = styled(DarkYellowCircle).attrs((props) => ({
  className: `DeviationIconContainer ${props.className}`,
}))`
  left: 0;
  margin-right: 1rem;
  margin-bottom: 0.2rem;
  background: ${(props) => props.theme.colors.deviationIconInfo} url(${ExclamationIcon}) no-repeat center center;
  background-size: 1.2rem 1.2rem;
  border: black 1px solid;
  z-index: ${(props) => props.theme.constants.zIndexMedium};
`;

export const InfoIcon = (): JSX.Element => (
  <SingleDeviationContainer>
    <InfoIconContainer />
  </SingleDeviationContainer>
);
